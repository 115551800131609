var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row form__body--card form__common--center"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card form--sroll1"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-10"},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit()}}},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3"},[_vm._v("キャンペーン名称"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formRegiterCampaign.name),expression:"formRegiterCampaign.name"}],staticClass:"form-control",class:{
                                                'is-invalid': _vm.submittedRegister && _vm.$v.formRegiterCampaign.name.$error
                                            },attrs:{"type":"text"},domProps:{"value":(_vm.formRegiterCampaign.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formRegiterCampaign, "name", $event.target.value)}}}),(_vm.submittedRegister && _vm.$v.formRegiterCampaign.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formRegiterCampaign.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('campaign.name') })))]):_vm._e(),(!_vm.$v.formRegiterCampaign.name.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('campaign.name') }))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3",attrs:{"for":"validationCustom05"}},[_vm._v("営業担当")]),_c('div',{staticClass:"col-sm-8"},[_c('Multiselect',{attrs:{"id":`select-sale-staff-object`,"value":_vm.formRegiterCampaign.sale_staff,"options":_vm.options.listDataMasterSaleStaf,"config":{
                                                trackBy: 'id',
                                                label: 'name',
                                                error: false,
                                                isRemove: false
                                            }},on:{"update:value":function($event){return _vm.$set(_vm.formRegiterCampaign, "sale_staff", $event)}}})],1)]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-3"},[_vm._v("ディレクション担当")]),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"width":"60px","min-width":"fit-content"}},[_vm._v("メイン")]),_c('div',{staticClass:"col-sm-12"},[_c('Multiselect',{attrs:{"id":`select-pic-object`,"value":_vm.formRegiterCampaign.person_in_charge,"options":_vm.options.listDataMasterInstructorStaf,"config":{
                                                                trackBy: 'id',
                                                                label: 'name',
                                                                error: false,
                                                                isRemove: false
                                                            }},on:{"update:value":function($event){return _vm.$set(_vm.formRegiterCampaign, "person_in_charge", $event)}}})],1)])])])])])])])])])])])]),_c('Footer',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-light mr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                            path: '/campaign/list'
                        })}}},[_vm._v(" "+_vm._s(_vm.$t('btn.cancel'))+" ")]),(_vm.checkPermission('campaign.register') && _vm.checkPermiss(['part_time']))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.formSubmit}},[_vm._v(" 承認申請 ")]):_vm._e(),(_vm.checkPermission('campaign.register') && _vm.checkPermiss(['administrator', 'sale', 'instructor']))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.$t('btn.register'))+" ")]):_vm._e()])])])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
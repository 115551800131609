<script>
import Layout from '@/views/layouts/main';
import appConfig from '@/app.config';
import Footer from '@/components/Footer/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';

import { masterMethods, campaignMethods, employeeMethods } from '@/state/helpers';
import { checkPermission, checkPermissionName } from '@/utils/handlers';
import { maxLength, required } from 'vuelidate/lib/validators';
import { showMessage } from '@/utils/messages';

export default {
    page: {
        title: 'キャンペーン新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        Layout,
        Footer,
        Multiselect
    },
    data() {
        return {
            formRegiterCampaign: {
                name: '',
                sale_staff: null,
                person_in_charge: null
            },
            options: {
                listDataMasterSaleStaf: [],
                listDataMasterInstructorStaf: []
            },
            submittedRegister: false,
            isLoading: false
        };
    },
    validations: {
        formRegiterCampaign: {
            name: { required, maxLength: maxLength(255) }
        }
    },

    methods: {
        checkPermission,
        ...masterMethods,
        ...campaignMethods,
        ...employeeMethods,

        checkPermiss(listRole) {
            return checkPermissionName(listRole);
        },
        getListMasterSaleStaf() {
            this.listMaster('users?filters[role]=2' + '&limit=999').then((data) => {
                this.options.listDataMasterSaleStaf = data;
            });
        },

        async getListMasterInstructorStaf() {
            let query = `page=1&limit=999&sorts[id]=desc&filters[team]=${process.env.VUE_APP_CUSTOMER_TEAM_PIC}`;
            let arrTwoInstructorStaf = [];
            try {
                const listUserFilterRole = await this.listMaster('users?filters[role]=3,1&limit=999').catch((error) => {
                    console.log('listUserFilterRole', error);
                });
                const listUserFilterTeam = await this.listEmployee(query).catch((error) => {
                    console.log('listUserFilterTeam', error);
                });
                arrTwoInstructorStaf = [...listUserFilterRole, ...listUserFilterTeam.data];
                this.listProjectManagement = listUserFilterRole;
                this.options.listDataMasterInstructorStaf = arrTwoInstructorStaf.reduce((acc, current) => {
                    const x = acc.find((item) => item.id === current.id);
                    if (!x) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
            } catch (error) {
                console.log(error);
            }
        },
        formSubmit() {
            this.submittedRegister = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            let obj = this.handleMapData(this.formRegiterCampaign);
            if (this.isLoading) return;
            this.isLoading = true;
            this.registerCampaign(obj).then((data) => {
                this.isLoading = false;
                if (data.code == 200) {
                    this.$router.push({ path: '/campaign/form/view', query: { id: data.data.id } }).then(() => {
                        showMessage(data.code, this.$bvToast, 'キャンペーンが登録されました。');
                    });
                } else {
                    showMessage(data.code, this.$bvToast, this.$t(data.message));
                }
            });
        },
        handleMapData(dataForm) {
            return {
                name: dataForm.name,
                sale_staff: dataForm.sale_staff
                    ? {
                          id: dataForm.sale_staff ? dataForm.sale_staff.id : null
                      }
                    : null,
                person_in_charge: dataForm.person_in_charge
                    ? {
                          id: dataForm.person_in_charge ? dataForm.person_in_charge.id : null
                      }
                    : null
            };
        }
    },
    mounted() {
        this.getListMasterSaleStaf();
        this.getListMasterInstructorStaf();
    }
};
</script>

<template>
    <Layout>
        <div class="row form__body--card form__common--center">
            <div class="col-lg-12">
                <div class="card form--sroll1">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-10">
                                <form class="needs-validation" @submit.prevent="formSubmit()" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-3">キャンペーン名称<span class="text-danger">*</span></label>
                                        <div class="col-sm-8">
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="formRegiterCampaign.name"
                                                :class="{
                                                    'is-invalid': submittedRegister && $v.formRegiterCampaign.name.$error
                                                }"
                                            />
                                            <div v-if="submittedRegister && $v.formRegiterCampaign.name.$error" class="invalid-feedback">
                                                <span v-if="!$v.formRegiterCampaign.name.required">{{
                                                    $t('validateField.required', {
                                                        field: $t('campaign.name')
                                                    })
                                                }}</span>
                                                <span v-if="!$v.formRegiterCampaign.name.maxLength">
                                                    {{
                                                        $t('validateField.max255', {
                                                            field: $t('campaign.name')
                                                        })
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">営業担当</label>
                                        <div class="col-sm-8">
                                            <Multiselect
                                                :id="`select-sale-staff-object`"
                                                :value.sync="formRegiterCampaign.sale_staff"
                                                :options="options.listDataMasterSaleStaf"
                                                :config="{
                                                    trackBy: 'id',
                                                    label: 'name',
                                                    error: false,
                                                    isRemove: false
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3">ディレクション担当</label>
                                        <div class="col-sm-8">
                                            <div class="row">
                                                <div class="col-sm-8">
                                                    <div class="d-flex align-items-center">
                                                        <span style="width: 60px; min-width: fit-content">メイン</span>
                                                        <div class="col-sm-12">
                                                            <Multiselect
                                                                :id="`select-pic-object`"
                                                                :value.sync="formRegiterCampaign.person_in_charge"
                                                                :options="options.listDataMasterInstructorStaf"
                                                                :config="{
                                                                    trackBy: 'id',
                                                                    label: 'name',
                                                                    error: false,
                                                                    isRemove: false
                                                                }"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer>
            <div class="d-flex justify-content-center">
                <div class="text-center flex-fill">
                    <button
                        type="button"
                        class="btn btn-light mr-3"
                        @click="
                            $router.push({
                                path: '/campaign/list'
                            })
                        "
                    >
                        {{ $t('btn.cancel') }}
                    </button>

                    <button
                        v-if="checkPermission('campaign.register') && checkPermiss(['part_time'])"
                        type="submit"
                        @click="formSubmit"
                        class="btn btn-primary"
                    >
                        承認申請
                    </button>
                    <button
                        v-if="checkPermission('campaign.register') && checkPermiss(['administrator', 'sale', 'instructor'])"
                        type="submit"
                        @click="formSubmit"
                        class="btn btn-primary"
                    >
                        {{ $t('btn.register') }}
                    </button>
                </div>
            </div>
        </Footer>
    </Layout>
</template>
<style scoped>
.page-content.mystyle .form__body--card {
    max-width: 100%;
    margin-left: 0;
}
</style>
